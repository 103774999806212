import { t } from '@lingui/macro'

export function GameShowIconName(name: string) {
  return (
    {
      'nc_original': 'nc_original',
      'nc_exclusive': 'nc_exclusive',
      'live': 'live',
      'electronic': 'electronic',
      'sport': 'sport',
      'chess': 'chess',
      'fishing': 'fishing',
      'tiger': 'tiger',
      'texas': 'texas',
      '21spot': '21spot',
      'baccarat': 'baccarat',
      'jjyx': 'jjyx',
      'cpyx': 'cpyx',
      'zmyx': 'zmyx',
    }[name] || 'live'
  )
}

export function LayoutScrollTop(top?: number, isSmooth?: boolean) {
  const rightDom = document.querySelector('#pageContentRight')
  if (rightDom) {
    isSmooth
      ? rightDom.scrollTo({
          top: top || 0,
          behavior: 'smooth',
        })
      : rightDom.scrollTo(0, top || 0)
  }
}

export function currentScrollTop() {
  const rightDom = document.querySelector('#pageContentRight')
  if (rightDom) {
    return rightDom.scrollTop
  }
}

/**
 * 展示”查看更多“最小数量
 */
export const MinTotalForShowMore = 21

/**
 * 游戏快照列表每页显示数量
 */
export const GameListPageSize = 7

/**
 * 游戏快照列表右侧 padding
 */
export const GameListPaddingRight = 70
/**
 * 游戏快照列表媒体查询断点
 */
export const GameListBreakpoints = {
  1300: {
    perPage: 6,
  },
  1200: {
    perPage: 5,
  },
}

/**
 * 体育 typeCd 标识
 */
export const SportsTypeCd = 'bottom'

/**
 * 体育标识
 */
export const SportsUrlFlag = '/ty'

/** 原创游戏标识 */
export enum RaGameTypeEnum {
  ra = 'RA', // 自己的游戏
  provider = 'Provider',
  /** pg 游戏 */
  raPgGame = 'RA_pg_electronic',
  raXpgGame = 'RA_xpg_electronic',
}

/**
 * 需要处理的游戏 code
 */
export const RAGameList: string[] = [RaGameTypeEnum.ra, RaGameTypeEnum.raPgGame, RaGameTypeEnum.raXpgGame]

/** 哈希大作战策略类型 */
export enum HashStrategyTypeEnum {
  /** 大小 */
  bigOrSmall = 'big_or_small',
  /** 数字 */
  number = 'number',
  /** 单双 */
  parity = 'parity',
}

/** 获取哈希大作战策略名称 */
export function getHashStrategyTypeName(type: HashStrategyTypeEnum) {
  return {
    [HashStrategyTypeEnum.bigOrSmall]: t`features_home_component_own_game_index_gfdj7d8wpc`,
    [HashStrategyTypeEnum.number]: t`features_home_component_own_game_index_xjqosove4j`,
    [HashStrategyTypeEnum.parity]: t`features_home_component_own_game_index_wmldzwyjjl`,
  }[type]
}

/** 获取哈希大作战策略背景样式名称 */
export function getHashStrategyTypeBgClassName(type: HashStrategyTypeEnum) {
  return {
    [HashStrategyTypeEnum.bigOrSmall]: 'number-bg',
    [HashStrategyTypeEnum.number]: 'size-bg',
    [HashStrategyTypeEnum.parity]: 'odd-even-bg',
  }[type]
}
